<template>
  <div class="account-info text-left fs-13">

    <div class="full-width d-flex justify-between m-b-20 border-b si-border p-b-10 p-t-10">
      <span v-if="loadedNetsuiteData.netsuite_id">
        <a class="fs-16 semibold" :href="loadedNetsuiteData.netsuite_link" target="_blank">
          {{ loadedNetsuiteData.netsuite_id }}
        </a>
      </span>
      <span class="fs-16 semibold" v-else>
        {{ $i18n.tc('default_no_data') }}
      </span>

      <div class="m-l-5">
        <span class="label si-label" :class="'label-' + loadedNetsuiteData.sync_status">{{ loadedNetsuiteData.formatted_sync_status }}</span>
      </div>
    </div>

    <ul class="list-unstyled m-b-0">
      <li class="row border-b si-border m-b-5 margin-horizontal-none p-b-5">
        <span class="col-md-5 p-l-0 ls-03 text-muted">Netsuite Id</span>
        <span class="col-md-4 p-r-0 semibold dark">
          <template v-if="loadedNetsuiteData.netsuite_id">
            {{loadedNetsuiteData.netsuite_id}}
          </template>
          <template v-else>
            {{ $i18n.tc('default_no_data') }}
          </template>
        </span>
      </li>

      <li class="row border-b si-border m-b-5 margin-horizontal-none p-b-5">
        <span class="col-md-5 p-l-0 ls-03 text-muted">Last Sync At</span>
        <span class="col-md-7 semibold dark">
          <template v-if="loadedNetsuiteData.last_netsuite_sync_at">
            {{loadedNetsuiteData.last_netsuite_sync_at | formatSIDateWithTime}}
          </template>
          <template v-else>
            {{ $i18n.tc('default_no_data') }}
          </template>
        </span>
      </li>

      <li class="row m-b-10 margin-horizontal-none p-b-10">
        <span class="col-md-5 p-l-0 ls-03 text-muted">View Logs</span>
        <span class="semibold dark col-md-7 p-r-0">
          <a class="semibold" :href="loadedNetsuiteData.logs_path" target="_blank">
            View Logs
          </a>
        </span>
      </li>
    </ul>
  </div>
</template>
<script>
  import { mapState } from 'vuex';

  export default {
    name: 'NetsuiteDataTooltip',
    props: {
      netsuiteId: String
    },
    computed: {
      ...mapState('GeneralConfiguration', ['netsuiteData']),
      loadedNetsuiteData: function() {
        return this.netsuiteData[this.netsuiteId];
      }
    }
  }
</script>
