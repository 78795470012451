var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "netsuiteContent",
      staticClass: "m-r-10",
      on: { mouseover: _vm.loadNetsuiteData }
    },
    [
      !_vm.loadedNetsuiteData
        ? _c("span", { staticClass: "tooltip-target d-block full-width" }, [
            _c("span", [
              _c(
                "a",
                {
                  staticClass: "lh-123 d-block clickable-item card-assign-user",
                  attrs: { href: "javascript:void(0)", target: "_blank" }
                },
                [
                  _c("img", {
                    staticClass: "img img-responsive full-width",
                    attrs: { src: _vm.netsuite_favicon }
                  })
                ]
              )
            ])
          ])
        : _c(
            "v-popover",
            {
              attrs: {
                popoverWrapperClass: "account-tooltip",
                placement: _vm.placementValue,
                openClass: _vm.popoverClasses,
                trigger: "hover",
                popoverArrowClass: "hide"
              }
            },
            [
              _c("span", [
                _c(
                  "a",
                  {
                    staticClass:
                      "lh-123 d-block clickable-item card-assign-user",
                    attrs: {
                      href:
                        _vm.loadedNetsuiteData.netsuite_link ||
                        "javascript:void(0)",
                      target: "_blank"
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "img img-responsive full-width",
                      attrs: { src: _vm.netsuite_favicon }
                    })
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "template",
                { slot: "popover" },
                [
                  _c("NetsuiteDataTooltip", {
                    attrs: { netsuiteId: _vm.netsuiteId.toString() }
                  })
                ],
                1
              )
            ],
            2
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }