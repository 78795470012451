var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("aside", { staticClass: "col-md-2 si-sidebar" }, [
    _c("div", { staticClass: "sidebar-brand" }, [
      _c("a", { staticClass: "si-brand-link", attrs: { href: "/admin" } }),
      _vm._v(" "),
      _c("div", {
        staticClass: "sidebar-manage-icon",
        attrs: { "data-behavior": "sidebar-manage-icon" },
        on: { click: _vm.toggleSidebarStateCookie }
      }),
      _vm._v(" "),
      _vm._m(0)
    ]),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "list-group panel-group" },
      [
        _vm.gsp.view_home_views_reports
          ? _c(
              "li",
              { ref: "admin/homeviews#index", staticClass: "list-group-item" },
              [
                _c(
                  "a",
                  {
                    staticClass: "toggle-link",
                    attrs: { href: _vm.homeviewsBizDevReportListing }
                  },
                  [
                    _c("span", { staticClass: "sidebar-link-title" }, [
                      _vm._v("Home View")
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "link-icon" },
                      [_c("DashboardIcon")],
                      1
                    )
                  ]
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        false
          ? _c("li", { staticClass: "list-group-item" }, [
              _c(
                "a",
                {
                  staticClass: "toggle-link",
                  attrs: { href: "javascript:void(0)" }
                },
                [
                  _c("span", { staticClass: "sidebar-link-title" }, [
                    _vm._v("Goals")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "link-icon" }, [_c("GoalsIcon")], 1)
                ]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "li",
          { ref: "admin/message_chains#index", staticClass: "list-group-item" },
          [
            _c(
              "a",
              {
                staticClass: "toggle-link",
                attrs: { href: _vm.MessageChainIndexPath }
              },
              [
                _c("span", { staticClass: "sidebar-link-title" }, [
                  _vm._v("Inbox")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "link-icon" }, [_c("InboxIcon")], 1)
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("li", { ref: "calendar_link", staticClass: "list-group-item" }, [
          _c(
            "a",
            { staticClass: "toggle-link", attrs: { href: "/admin/calendar" } },
            [
              _c("span", { staticClass: "sidebar-link-title" }, [
                _vm._v("Calendar")
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "link-icon" }, [_c("CalendarIcon")], 1)
            ]
          )
        ]),
        _vm._v(" "),
        _vm.gsp.view_tasks
          ? _c("li", { ref: "tasks_link", staticClass: "list-group-item" }, [
              _c(
                "a",
                {
                  staticClass: "toggle-link",
                  attrs: { href: _vm.taskListing }
                },
                [
                  _c("span", { staticClass: "sidebar-link-title" }, [
                    _vm._v("Tasks")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "link-icon" }, [_c("TasksIcon")], 1)
                ]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.gsp.view_work_orders
          ? _c(
              "li",
              {
                ref: "admin/workorders/work_orders#index",
                staticClass: "list-group-item"
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "toggle-link",
                    attrs: { href: _vm.workOrderListing }
                  },
                  [
                    _c("span", { staticClass: "sidebar-link-title" }, [
                      _vm._v("Work Orders")
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "link-icon" },
                      [_c("WorkOrdersIcon")],
                      1
                    )
                  ]
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "sidebarCollectionLink",
          {
            attrs: {
              tabName: "Resourcing",
              isSidebarActive: _vm.isSidebarActive,
              mainLink: _vm.resourceListing,
              tabOptions: [
                {
                  url: _vm.resourceListing,
                  ref: "admin/resourcing#show",
                  display_name: "Resourcing View",
                  display: true
                },
                {
                  url: _vm.resourceReportListing,
                  ref: "admin/resourcing#report",
                  display_name: "Resourcing Report",
                  display: true
                }
              ]
            }
          },
          [_c("span", { staticClass: "link-icon" }, [_c("ResourcingIcon")], 1)]
        ),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c(
          "sidebarCollectionLink",
          {
            attrs: {
              tabName: "Accounts & Contacts",
              isSidebarActive: _vm.isSidebarActive,
              mainLink: _vm.gsp.view_contacts && _vm.contactsListing,
              tabOptions: [
                {
                  url: _vm.contactsListing,
                  ref: "contacts_navigation_link",
                  display_name: "Contacts",
                  display: _vm.gsp.view_contacts
                },
                {
                  url: _vm.accountsListing,
                  ref: "admin/accounts#index",
                  display_name: "Accounts",
                  display: _vm.gsp.view_accounts
                },
                {
                  url: _vm.designsListing,
                  ref: "admin/designs#index",
                  display_name: "Designs",
                  display: _vm.gsp.view_orders
                },
                {
                  url: _vm.parentsListing,
                  ref: "admin/companies#index",
                  display_name: "Parent Companies",
                  display: _vm.gsp.view_accounts
                },
                {
                  url: _vm.productConfigurationListing,
                  ref: "product_configurations_link",
                  display_name: "Products",
                  display: _vm.gsp.view_accounts
                }
              ]
            }
          },
          [_c("i", { staticClass: "icon--bizdev link-icon" })]
        ),
        _vm._v(" "),
        _vm.gsp.view_orders
          ? _c(
              "li",
              {
                ref: "admin/opportunities#index",
                staticClass: "list-group-item"
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "toggle-link",
                    attrs: { href: _vm.opportunityListing }
                  },
                  [
                    _c("span", { staticClass: "sidebar-link-title" }, [
                      _vm._v("Opportunities")
                    ]),
                    _vm._v(" "),
                    _c("i", { staticClass: "icon--bizdev link-icon" })
                  ]
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.gsp.view_orders
          ? _c(
              "li",
              { ref: "admin/estimates#index", staticClass: "list-group-item" },
              [
                _c(
                  "a",
                  {
                    staticClass: "toggle-link",
                    attrs: { href: _vm.estimateListing }
                  },
                  [
                    _c("span", { staticClass: "sidebar-link-title" }, [
                      _vm._v("Estimates")
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "link-icon" },
                      [_c("EstimatesIcon")],
                      1
                    )
                  ]
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.gsp.view_orders
          ? _c(
              "li",
              { ref: "admin/orders#index", staticClass: "list-group-item" },
              [
                _c(
                  "a",
                  {
                    staticClass: "toggle-link",
                    attrs: { href: _vm.orderListing }
                  },
                  [
                    _c("span", { staticClass: "sidebar-link-title" }, [
                      _vm._v("Orders")
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "link-icon" },
                      [_c("OrdersIcon")],
                      1
                    )
                  ]
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.gsp.view_orders
          ? _c(
              "li",
              { ref: "purchase_orders_link", staticClass: "list-group-item" },
              [
                _c(
                  "a",
                  {
                    staticClass: "toggle-link",
                    attrs: { href: _vm.purchaseOrdersListing }
                  },
                  [
                    _c("span", { staticClass: "sidebar-link-title" }, [
                      _vm._v("Purchase Orders")
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "link-icon" },
                      [_c("PurchaseOrdersIcon")],
                      1
                    )
                  ]
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.gsp.view_orders
          ? _c("li", { ref: "shipment_link", staticClass: "list-group-item" }, [
              _c(
                "a",
                {
                  staticClass: "toggle-link",
                  attrs: { href: _vm.shipmentFilesListing }
                },
                [
                  _c("span", { staticClass: "sidebar-link-title" }, [
                    _vm._v("Shipment Allocation")
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "link-icon" },
                    [_c("ShipmentsIcon")],
                    1
                  )
                ]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "sidebarCollectionLink",
          {
            attrs: {
              tabName: "Catalog Management",
              isSidebarActive: _vm.isSidebarActive,
              mainLink: _vm.gsp.view_catalog_manager && _vm.productsListing,
              tabOptions: [
                {
                  url: _vm.productsListing,
                  ref: "admin/catalog/products#index",
                  display_name: "Items",
                  display: _vm.gsp.view_catalog_manager
                },
                {
                  url: _vm.supplierListing,
                  ref: "admin/catalog/suppliers#index",
                  display_name: "Suppliers",
                  display: _vm.gsp.view_catalog_manager
                },
                {
                  url: _vm.sizeListing,
                  ref: "catalog_properties_for_highlight",
                  display_name: "Catalog Properties",
                  display: _vm.gsp.view_catalog_manager
                }
              ]
            }
          },
          [
            _c(
              "span",
              { staticClass: "link-icon" },
              [_c("CatalogManagementIcon")],
              1
            )
          ]
        ),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c(
          "sidebarCollectionLink",
          {
            attrs: {
              tabName: "Accounting",
              isSidebarActive: _vm.isSidebarActive,
              mainLink:
                _vm.gsp.view_accounting && _vm.accountingAccountsListing,
              tabOptions: [
                {
                  url: _vm.accountingAccountsListing,
                  ref: "admin/accountings#accounts",
                  display_name: "Accounts",
                  display: _vm.gsp.view_accounting
                },
                {
                  url: _vm.accountingOpportunitiesListing,
                  ref: "admin/accountings#opportunities",
                  display_name: "Opportunities",
                  display: _vm.gsp.view_accounting
                },
                {
                  url: _vm.accountingEstimatesListing,
                  ref: "admin/accountings#estimates",
                  display_name: "Estimates",
                  display: _vm.gsp.view_accounting
                },
                {
                  url: _vm.accountingOrdersListing,
                  ref: "admin/accountings#orders",
                  display_name: "Orders",
                  display: _vm.gsp.view_accounting
                },
                {
                  url: _vm.accountingPurchaseOrdersListing,
                  ref: "admin/accountings#purchase_orders",
                  display_name: "Purchase Orders",
                  display: _vm.gsp.view_accounting
                }
              ]
            }
          },
          [_c("i", { staticClass: "icon--bizdev link-icon" })]
        ),
        _vm._v(" "),
        _c(
          "sidebarCollectionLink",
          {
            attrs: {
              tabName: "Reports & Dashboards",
              isSidebarActive: _vm.isSidebarActive,
              mainLink:
                _vm.gsp.view_work_order_reports && _vm.workOrderReportListing,
              tabOptions: [
                {
                  url: _vm.workOrderReportListing,
                  ref: "work_order_reports",
                  display_name: "Work Order Report",
                  display: _vm.gsp.view_work_order_reports
                },
                {
                  url: _vm.grossProfitReportListing,
                  ref: "admin/reports/financial_reports/gross_profit_report",
                  display_name: "Gross Revenue Report",
                  display: _vm.gsp.view_financial_reports
                },
                {
                  url: _vm.workInProcessReportListing,
                  ref: "work_in_process_reports",
                  display_name: "Work In Process Report",
                  display: _vm.gsp.view_financial_reports
                },
                {
                  url: _vm.accountReceivableReportListing,
                  ref: "account_receivable_report",
                  display_name: "Account Receivable Report",
                  display: _vm.gsp.view_financial_reports
                },
                {
                  url: _vm.unconfirmedPOReportListing,
                  ref: "unconfirmed_pos_report",
                  display_name: "Unconfirmed POs Report",
                  display: _vm.gsp.view_financial_reports
                },
                {
                  url: _vm.amTodosDashboard,
                  ref: "am_todos_dashboard",
                  display_name: "To-Dos Dashboards",
                  display: this.canShowTodosDashboard(
                    _vm.gsp.view_todo_dashboards
                  )
                },
                {
                  url: _vm.poReportListing,
                  ref: "po_report_listing",
                  display_name: "PO Report",
                  display: _vm.gsp.view_financial_reports
                },
                {
                  url: _vm.latePoReportListing,
                  ref: "late_po_report_listing",
                  display_name: "Late PO Report",
                  display: _vm.gsp.view_financial_reports
                },
                {
                  url: _vm.overdueOrderReportListing,
                  ref: "overdue_order_report_listing",
                  display_name: "Overdue Order Report",
                  display: _vm.gsp.view_financial_reports
                },
                {
                  url: _vm.newAccountRevenueReportListing,
                  ref: "new_account_revenue_report_listing",
                  display_name: "New Account Revenue Report",
                  display: _vm.gsp.view_financial_reports
                },
                {
                  url: _vm.accountSpendReportListing,
                  ref: "account_spend_report_listing",
                  display_name: "Account Spend Report",
                  display: _vm.gsp.view_financial_reports
                },
                {
                  url: _vm.newContactRevenueReportListing,
                  ref: "new_contact_revenue_report_listing",
                  display_name: "New Contact Revenue Report",
                  display: _vm.gsp.view_financial_reports
                }
              ]
            }
          },
          [_c("i", { staticClass: "icon--reports link-icon" })]
        ),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c(
          "sidebarCollectionLink",
          {
            attrs: {
              tabName: "Employee Management",
              isSidebarActive: _vm.isSidebarActive,
              mainLink: _vm.gsp.view_users && _vm.userListing,
              tabOptions: [
                {
                  url: _vm.userListing,
                  ref: "admin/users#index",
                  display_name: "Employees",
                  display: _vm.gsp.view_users
                },
                {
                  url: _vm.departmentListing,
                  ref: "admin/teams#index",
                  display_name: "Departments",
                  display: _vm.gsp.view_accounts
                }
              ]
            }
          },
          [
            _c(
              "span",
              { staticClass: "link-icon" },
              [_c("EmployeeManagementIcon")],
              1
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "sidebarCollectionLink",
          {
            attrs: {
              tabName: "System Settings",
              isSidebarActive: _vm.isSidebarActive,
              mainLink: _vm.gsp.view_rules && _vm.rulesListing,
              tabOptions: [
                {
                  url: _vm.rulesListing,
                  ref: "rules-link-for-highlight",
                  display_name: "Rule Engine",
                  display: _vm.gsp.view_rules
                },
                {
                  url: _vm.emailTemplateListing,
                  ref: "admin/email_templates#index",
                  display_name: "Email Templates",
                  display: _vm.gsp.view_email_templates
                },
                {
                  url: _vm.screenPrintingUpchargeListing,
                  ref: "decoration_link_for_highlight",
                  display_name: "Upcharges List",
                  display: _vm.gsp.view_orders
                },
                {
                  url: _vm.screenPrintingLocationListing,
                  ref: "printing_locations",
                  display_name: "Print Locations List",
                  display: _vm.gsp.view_orders
                },
                {
                  url: _vm.productCustomizerListing,
                  ref: "admin/legacy_customizer_products#index",
                  display_name: "Product Customizer",
                  display: false
                },
                {
                  url: _vm.shippingCalculator,
                  ref: "admin/shipment_calculator#show",
                  display_name: "Shipment Calculator",
                  display: true
                },
                {
                  url: _vm.holdInstructionsPath,
                  ref: "hold_instructions",
                  display_name: "Hold Instructions",
                  display: _vm.gsp.view_orders
                },
                {
                  url: _vm.netsuiteLogsPath,
                  ref: "admin/netsuite_logs#index",
                  display_name: "Netsuite Logs",
                  display: true
                }
              ]
            }
          },
          [
            _c(
              "span",
              { staticClass: "link-icon" },
              [_c("SystemSettingsIcon")],
              1
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "si-nav-hamburger" }, [
      _c("span", { staticClass: "hamburger-inner" }),
      _vm._v(" "),
      _c("span", { staticClass: "brand-name" }, [_vm._v("Social Imprints")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }