var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "account-info text-left fs-13" }, [
    _c(
      "div",
      {
        staticClass:
          "full-width d-flex justify-between m-b-20 border-b si-border p-b-10 p-t-10"
      },
      [
        _vm.loadedNetsuiteData.netsuite_id
          ? _c("span", [
              _c(
                "a",
                {
                  staticClass: "fs-16 semibold",
                  attrs: {
                    href: _vm.loadedNetsuiteData.netsuite_link,
                    target: "_blank"
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.loadedNetsuiteData.netsuite_id) +
                      "\n      "
                  )
                ]
              )
            ])
          : _c("span", { staticClass: "fs-16 semibold" }, [
              _vm._v(
                "\n      " + _vm._s(_vm.$i18n.tc("default_no_data")) + "\n    "
              )
            ]),
        _vm._v(" "),
        _c("div", { staticClass: "m-l-5" }, [
          _c(
            "span",
            {
              staticClass: "label si-label",
              class: "label-" + _vm.loadedNetsuiteData.sync_status
            },
            [_vm._v(_vm._s(_vm.loadedNetsuiteData.formatted_sync_status))]
          )
        ])
      ]
    ),
    _vm._v(" "),
    _c("ul", { staticClass: "list-unstyled m-b-0" }, [
      _c(
        "li",
        {
          staticClass:
            "row border-b si-border m-b-5 margin-horizontal-none p-b-5"
        },
        [
          _c("span", { staticClass: "col-md-5 p-l-0 ls-03 text-muted" }, [
            _vm._v("Netsuite Id")
          ]),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "col-md-4 p-r-0 semibold dark" },
            [
              _vm.loadedNetsuiteData.netsuite_id
                ? [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.loadedNetsuiteData.netsuite_id) +
                        "\n        "
                    )
                  ]
                : [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$i18n.tc("default_no_data")) +
                        "\n        "
                    )
                  ]
            ],
            2
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          staticClass:
            "row border-b si-border m-b-5 margin-horizontal-none p-b-5"
        },
        [
          _c("span", { staticClass: "col-md-5 p-l-0 ls-03 text-muted" }, [
            _vm._v("Last Sync At")
          ]),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "col-md-7 semibold dark" },
            [
              _vm.loadedNetsuiteData.last_netsuite_sync_at
                ? [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm._f("formatSIDateWithTime")(
                            _vm.loadedNetsuiteData.last_netsuite_sync_at
                          )
                        ) +
                        "\n        "
                    )
                  ]
                : [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$i18n.tc("default_no_data")) +
                        "\n        "
                    )
                  ]
            ],
            2
          )
        ]
      ),
      _vm._v(" "),
      _c("li", { staticClass: "row m-b-10 margin-horizontal-none p-b-10" }, [
        _c("span", { staticClass: "col-md-5 p-l-0 ls-03 text-muted" }, [
          _vm._v("View Logs")
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "semibold dark col-md-7 p-r-0" }, [
          _c(
            "a",
            {
              staticClass: "semibold",
              attrs: {
                href: _vm.loadedNetsuiteData.logs_path,
                target: "_blank"
              }
            },
            [_vm._v("\n          View Logs\n        ")]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }