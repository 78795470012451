<template>
  <div @mouseover="loadNetsuiteData" class="m-r-10" ref="netsuiteContent">
    <span class='tooltip-target d-block full-width' v-if="!loadedNetsuiteData">
      <span>
        <a href="javascript:void(0)" class="lh-123 d-block clickable-item card-assign-user" target="_blank">
          <img :src="netsuite_favicon" class="img img-responsive full-width">
        </a>
      </span>
    </span>
    <v-popover popoverWrapperClass="account-tooltip" :placement='placementValue' :openClass="popoverClasses" trigger='hover' popoverArrowClass='hide' v-else>
      <span>
        <a :href="loadedNetsuiteData.netsuite_link || 'javascript:void(0)'" class="lh-123 d-block clickable-item card-assign-user" target="_blank">
          <img :src="netsuite_favicon" class="img img-responsive full-width">
        </a>
      </span>
      <template slot='popover'>
        <NetsuiteDataTooltip
          :netsuiteId="netsuiteId.toString()"
        ></NetsuiteDataTooltip>
      </template>
    </v-popover>
  </div>
</template>
<script>
  import { mapActions, mapState } from 'vuex';
  import NetsuiteDataTooltip from 'components/shared/netsuite/netsuite_data_tooltip.vue';

  export default {
    name: 'NetsuiteGridContent',
    props: {
      netsuiteId: String,
      placementValue: {
        type: String,
        default: 'bottom'
      },
      popoverClasses: {
        type: String,
        default: ''
      },
      showLabels: {
        type: Boolean,
        default: false
      }
    },
    mounted() {
      this.calculateWidth();
      this.bindWindowEvents();
    },
    data: function() {
      return {
        clientWidth: 0
      }
    },
    components: {
      NetsuiteDataTooltip
    },
    methods: {
      ...mapActions('GeneralConfiguration', ['fetchNetsuiteData']),
      loadNetsuiteData: function() {
        if(!this.loadedNetsuiteData) {
          window.disableAjaxLoader = true;
          this.fetchNetsuiteData({
            apollo: this.$apollo,
            netsuiteId: this.netsuiteId
          }).then(() => window.disableAjaxLoader = false);
        }
      },
      calculateWidth() {
        this.clientWidth = this.$refs.netsuiteContent.clientWidth;
      },
      bindWindowEvents() {
        var _this = this;
        window.addEventListener('sidebarToggled', (event) => {
          _this.calculateWidth();
        });
      }
    },
    computed: {
      ...mapState('GeneralConfiguration', ['netsuiteData']),
      loadedNetsuiteData: function() {
        return this.netsuiteData[this.netsuiteId];
      },
      withNameWidth() {
        return (this.clientWidth >= 170);
      },
      netsuite_favicon() {
        return gon.netsuiteFaviconUrl
      }
    }
  }
</script>
