<template>
  <div>

    <si-modal @escapePressed='hideModal' v-if='modalDataPresent' :modalOptions="modalOptions" ref="taskDetailModal" @onDismiss="handleModalClose()">
      <template slot="modal-content">

        <!-- cancelled text banner-->
        <div class="row row-20 log-time-header-row" v-if='!task.is_archived || currentUser.owner_or_super_admin'>
          <div class="alert alert-danger m-b-0 text-center" v-if='task.cancelled_at'>
            <span class="btn-icon-helper cancel-task-info-icon"></span>
            {{ this.$i18n.t('task_modal.cancelled_text') }} {{ task.cancelled_at | formatSIExpandedDateTime }}
          </div>

          <!-- cancelled action buttons -->
          <div class="p-l-r-20 p-t-b-10 d-flex justify-between">
            <span>
              <mark-complete-button
                @markComplete='showMarkCompleteModal'
                @markInComplete='markInComplete(task)'
                :is_completed='this.task.is_completed'
                :is_cancelled='this.task.is_cancelled'
                :task_details='{ id: this.task.id, details: this.task.title }'
              >
              </mark-complete-button>

              <span class="m-l-10" v-if='currentUser.owner_or_super_admin || !this.task.is_completed'>
                <log-time-button
                  @logTime='showTimeLogModalForm'>
                </log-time-button>
              </span>
            </span>

            <div class="align-right p-t-b-5 justify-between">
              <NetsuiteGridContent :netsuiteId="'Task-' + task.id"></NetsuiteGridContent>
            </div>

            <span class="add-edit-col nowrap">
              <div class="top-action-group">
                <div data-menu="dropdown" class="dropdown d-inline-block">
                  <span type="button" data-toggle="dropdown" aria-expanded="false" class="setting-dropdown-toggle btn-h-40-only btn dropdown-toggle btn-settings normal js-show-button">
                    <span class="btn-icon-helper gear-icon m-r-r"></span>
                    <span class="caret"></span>
                  </span>

                  <ul class="dropdown-menu settings-dropdown dropdown-menu-right" v-if='!task.is_archived || currentUser.owner_or_super_admin'>
                    <li v-if='!task.restrict_editable' :data-task-complete-field="task.id">
                      <sweet-alert @input="deleteTask(task.id)" container_classes='dropdown-div d-flex'>
                        <template>
                          <a class="full-width del" href='javascript:void(0)' data-sweet-alert-confirm='Are you sure you want to delete this task?' data-heading='Delete Task' :data-task-complete-field="task.id" data-confirm-type='delete' :data-description='this.$i18n.t("vue_task_list.entity_details", { entity_id: task.id, entity_title: task.title })' data-vue-component=true :data-confirm-button-text='this.$i18n.tc("vue_task_list.delete_permanently")' :data-cancel-button-text='this.$i18n.tc("vue_task_list.cancel")'>{{ this.$i18n.tc('vue_task_list.delete') }}</a>
                        </template>
                      </sweet-alert>
                    </li>

                    <li v-if='!task.is_cancelled && !task.is_archived'>
                      <sweet-alert @input="cancelTask(task.id)" container_classes='dropdown-div d-flex'>
                        <a class="full-width" href='javascript:void(0)' data-heading='Cancel Task' :data-task-complete-field="task.id" data-confirm-type='cancel' :data-description='this.$i18n.t("vue_task_list.entity_details", { entity_id: task.id, entity_title: task.title })' data-vue-component=true data-sweet-alert-confirm='Are you sure you want to cancel this task?' :data-confirm-button-text='this.$i18n.tc("vue_task_list.cancel_task_button_text")' :data-cancel-button-text='this.$i18n.tc("vue_task_list.discard")'>{{ this.$i18n.tc('vue_task_list.cancel') }}</a>
                      </sweet-alert>
                    </li>

                    <li v-if='!task.is_archived'>
                      <sweet-alert @input="archiveTask(task.id)" container_classes='dropdown-div d-flex'>
                        <a class="full-width" href='javascript:void(0)' data-sweet-alert-confirm='Are you sure you want to archive this task?' data-heading='Archive Task' :data-task-complete-field="task.id" data-confirm-type='archive' :data-description='this.$i18n.t("vue_task_list.entity_details", { entity_id: task.id, entity_title: task.title })' data-vue-component=true :data-confirm-button-text='this.$i18n.tc("vue_task_list.archive")' :data-cancel-button-text='this.$i18n.tc("vue_task_list.cancel")'>{{ this.$i18n.tc('vue_task_list.archive') }}</a>
                      </sweet-alert>
                    </li>

                    <li v-if="task.netsuite_id">
                      <a target="_blank" :href="task.netsuite_link">Netsuite Link</a>
                    </li>
                  </ul>
                </div>
              </div>
            </span>
          </div>
        </div>

        <div v-if='this.task'>
          <form class="form" @submit.prevent data-maxFileSize=524288000 data-attachment-form=true data-upload-url='/admin/attachments'>
            <article class="">
              <div class="form-group m-b-0" data-error-container='form-group'>
                <in-place-editor
                  @editable='escapePressed'
                  :placeholder='this.$tc("task_modal.title_placeholder")'
                  input_value_class='task-title-edit-textarea'
                  display_text_class='task-title-edit-textarea hover-elem'
                  :can_be_edited='!task.is_archived || currentUser.owner_or_super_admin'
                  v-model='task.title'
                  :value='task.title'
                  @changed='submitForm()'
                ></in-place-editor>
                <error :errors="task.errors && task.errors.title"></error>
              </div>
            </article>

            <article class="task-dm-tab-section">
              <!-- Nav tabs -->
              <ul class="task-dm-tabs d-flex lh-38 list-unstyled" role="tablist" ref='tabList'>
                <li role="presentation" class="tab-li active" ref='detailsTab'>
                  <a href="#details" aria-controls="details" role="tab" data-toggle="tab" class="t-tab-link">{{ this.$i18n.t('task_modal.tabs.details') }}</a>
                </li>

                <li role="presentation" class="tab-li" ref='commentsTab'>
                  <a href="#comments" @click='loadComments' aria-controls="comments" role="tab" data-toggle="tab" class="t-tab-link">{{ this.$i18n.t('task_modal.tabs.comments') }} <span v-if='task.comments_count > 0'>({{ task.comments_count }})</span></a>
                </li>
                <li role="presentation" class="tab-li" ref='timeLogTab'>
                  <a href="#timelogs" @click='loadTimeLogs' aria-controls="timelogs" role="tab" data-toggle="tab" class="t-tab-link">{{ this.$i18n.t('task_modal.tabs.timelogs') }}</a>
                </li>
                <li role="presentation" class="tab-li" ref='historyTab'>
                  <a href="#history" @click='loadHistory' aria-controls="history" role="tab" data-toggle="tab" class="t-tab-link">{{ this.$i18n.t('task_modal.tabs.history') }}</a>
                </li>
              </ul>

              <!-- Tab panes -->
              <div class="tab-content m-t-10 hide-tooltip">

                <!-- Details section -->
                <div role="tabpanel" class="tab-pane active" id="details">
                  <div class="row row-col-space-8 m-l-0 m-r-0">
                    <div class="col-md-3 hover-elem p-l-0">
                      <label for="date_picker" class="d-flex align-center justify-between m-b-0-hard lh-40 pointer">
                        <span>{{ this.$i18n.t('task_modal.details.due_date_label') }}</span>
                        <svg data-v-3d990fac="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path data-v-3d990fac="" fill="#a1a8c3" fill-rule="evenodd" d="M15 7H9v1.5a.5.5 0 0 1-1 0V7H6a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1h-2v1.5a.5.5 0 1 1-1 0V7zm1-1h2a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h2V4.5a.5.5 0 0 1 1 0V6h6V4.5a.5.5 0 1 1 1 0V6zM5 11h14v1H5v-1z"></path></svg>
                      </label>
                    </div>
                    <div class="col-md-9 hover-elem">
                      <div class="lh-40 dark task-date-editable">
                        <date-field @focusIn='escapePressed' v-if='!task.is_archived || currentUser.owner_or_super_admin' ref="dateField" :not_format='true' v-model="task.virtual_due_date" id='date_picker' @changed='dueDateChanged'></date-field>
                        <span v-if='task.is_archived && !currentUser.owner_or_super_admin'>{{ task.virtual_due_date }}</span>
                        <span class="m-r-5">-</span>
                        <time-picker @focusIn='escapePressed' v-if='!task.is_archived || currentUser.owner_or_super_admin' time_icon_class='hide' ref='timeField' :field_value='task.virtual_due_time' :field_name="'task[virtual_due_time]'" :element_id="'task_due_time'"v-model="task.virtual_due_time" @time_value_update='dueDateChanged' placeholder='08:00 AM'></time-picker>
                        <span v-if='task.is_archived && !currentUser.owner_or_super_admin'>{{ task.virtual_due_time }}</span>
                      </div>
                      <error :errors="task.errors && ((task.errors.virtual_due_time || task.errors.due_time) || (task.errors.virtual_due_date || task.errors.due_date))"></error>
                    </div>
                  </div>

                  <div class="row row-col-space-8 m-l-0 m-r-0">
                    <div class="col-md-3 hover-elem p-l-0">
                      <label for="" class="d-flex align-center justify-between m-b-0-hard lh-40">
                        <span>{{ this.$i18n.t('task_modal.details.assignee_label') }}</span>
                        <svg class="pointer"  @click='editAssignee' data-v-3d990fac="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path data-v-3d990fac="" fill="#a1a8c3" fill-rule="evenodd" d="M16.327 17.503a4.991 4.991 0 0 0-8.654 0A6.97 6.97 0 0 0 12 19a6.97 6.97 0 0 0 4.327-1.497zm-9.407-.687a5.991 5.991 0 0 1 10.16 0 7 7 0 1 0-10.16 0zM12 13a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-1a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 8a8 8 0 1 1 0-16 8 8 0 0 1 0 16z"></path></svg>
                      </label>
                    </div>
                    <div class="col-md-9 hover-elem lh-40" v-if='task.assignee'>
                      <div v-if='!edit_assignee' @click='editAssignee' class="d-flex align-center pointer">
                        <user-popover-tooltip :user_id="assigneeId">
                          <span class="card-assign-user normal x-20">
                            <img :src="task.assignee.profile_picture_url" class="img-responsive">
                          </span>
                          <span class="dark fontmedium m-l-10">{{ task.assignee.actual_name }}</span>
                        </user-popover-tooltip>
                      </div>
                      <user-selector ref='assigneeSelector' v-show='edit_assignee' :user_options='assignee_options' :selected_option='selectedAssignee' :clearable='false' @userSelected='userChanged' @focus_out='edit_assignee = false' @escapePressed='escapePressed'></user-selector>
                    </div>
                  </div>

                  <div class="row row-col-space-8 m-l-0 m-r-0">
                    <div class="col-md-3 hover-elem p-l-0">
                      <label for="" class="d-flex align-center justify-between m-b-0-hard lh-40">
                        <span>{{ this.$i18n.t('task_modal.details.time_log_label') }}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                          <path fill="#A1A8C3" fill-rule="evenodd" d="M12 12h3.833a.5.5 0 1 1 0 1H11.5a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 1 0V12zm0 8a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm0-1a7 7 0 1 0 0-14 7 7 0 0 0 0 14z"/>
                        </svg>
                      </label>
                    </div>

                    <div class="col-md-9 hover-elem dark lh-40">
                      <div class="d-flex align-center fontmedium justify-between" ref='timeLoggedValue'>
                        <span class="">{{ task.total_time_logged | timeDurationFormat }}</span>
                        <span class="text blue show-on-hover m-r-15 pointer" @click='showTimeLogModalForm' v-if='(!this.task.is_completed || currentUser.owner_or_super_admin) && !task.is_archived || currentUser.owner_or_super_admin'>+ Log Time</span>
                      </div>
                    </div>
                  </div>


                  <!-- Followers -->
                  <div class="row row-col-space-8 m-l-0 m-r-0">
                    <div class="col-md-3 hover-elem p-l-0">
                      <label for="task_followers" class="d-flex align-center justify-between m-b-0-hard lh-40">
                        <span>{{ this.$i18n.t('task_modal.details.followers_label') }}</span>
                        <svg class="pointer" @click='editFollowers(true)' data-v-3d990fac="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path data-v-3d990fac="" fill="#a1a8c3" fill-rule="evenodd" d="M18.864 14.648c.668.172 1.136.793 1.136 1.505v3.33a.509.509 0 0 1-.5.517h-3a.509.509 0 0 1-.5-.517c0-.286.224-.517.5-.517H19v-2.813a.514.514 0 0 0-.378-.502l-3.243-.839a.511.511 0 0 1-.374-.429l-.253-1.835a.521.521 0 0 1 .287-.543c.89-.42 1.46-1.339 1.461-2.35v-.914c.024-1.276-.827-2.391-2.018-2.661a2.46 2.46 0 0 0-1.316.102.496.496 0 0 1-.638-.317.522.522 0 0 1 .306-.66 3.44 3.44 0 0 1 1.848-.138c1.67.378 2.85 1.925 2.818 3.684v.904c-.001 1.284-.658 2.46-1.708 3.108l.156 1.13 2.916.755zm-4.952 1.537c.644.19 1.088.799 1.088 1.492v1.806a.509.509 0 0 1-.5.517h-10a.509.509 0 0 1-.5-.517v-1.807c0-.692.444-1.3 1.088-1.491l2.463-.728.12-.868a3.948 3.948 0 0 1-2.483-.909.53.53 0 0 1-.09-.71c.67-.945.99-2.107.902-3.315 0-2 1.567-3.62 3.5-3.62s3.5 1.62 3.499 3.66a5.013 5.013 0 0 0 .904 3.274.53.53 0 0 1-.091.711 3.948 3.948 0 0 1-2.482.909l.12.868 2.462.728zM14 17.677a.515.515 0 0 0-.362-.497l-2.775-.82a.512.512 0 0 1-.358-.425l-.253-1.835c-.047-.345.24-.639.573-.584a2.946 2.946 0 0 0 1.985-.379A6.034 6.034 0 0 1 12 9.655c0-1.428-1.12-2.586-2.5-2.586S7 8.227 6.999 9.615a6.09 6.09 0 0 1-.81 3.522 2.946 2.946 0 0 0 1.986.379c.332-.055.62.24.573.584l-.253 1.835a.512.512 0 0 1-.358.424l-2.775.82a.515.515 0 0 0-.362.498v1.289h9v-1.29z"></path></svg>
                      </label>
                    </div>
                    <div class="col-md-9 pointer" :class="{ 'hover-elem' : can_manage }">
                      <!-- Change followers -->
                      <div class="wo-follower-block">
                        <auto-complete-followers ref='autoComplete' @focusOut='escapePressed' @escapePressed.stop='escapePressed' follower_width='x-32' v-show='(edit_followers || followersEmpty) && (!task.is_archived || currentUser.owner_or_super_admin)' v-model='task.task_follower_ids' :auto_suggest_followers=8 :placeholder="$t('quick_add_task.followers_placeholder')" :follower_options=" follower_options" :persisted_followers='task.task_follower_ids' field_for='task_followers' @followersChanged='updateFollower'></auto-complete-followers>
                      </div>
                      <!-- Followers list -->
                      <div @click='editFollowers(false)'>
                        <span class="lh-40" v-if='!edit_followers' v-for='follower in task.followers' >
                          <a href='javascript:void(0)' @click='editFollowers(false)' :key='follower.id'>
                            <div class='pop-over-box d-inline-block follower-tooltip'>
                              <UserProfilePicWithTooltip
                                :user_id='follower.id'
                                image_size='24'
                              ></UserProfilePicWithTooltip>
                            </div>
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="row row-col-space-8 m-l-0 m-r-0" @click='escapePressed'>
                    <div class="col-md-3 hover-elem p-l-0">
                      <label for="" class="d-flex align-center justify-between m-b-0-hard lh-40">
                        <span>{{ this.$i18n.t('task_modal.details.category_label') }}</span>
                        <svg data-v-3d990fac="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path data-v-3d990fac="" fill="#a1a8c3" fill-rule="evenodd" d="M6 19h12v-8.281L12 5.18 6 10.72V19zm-1 .5v-9a.5.5 0 0 1 .16-.367l6.5-6a.5.5 0 0 1 .68 0l6.5 6a.5.5 0 0 1 .16.367v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5zm7-6.5a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-3.5 4a.5.5 0 1 1 0-1h7a.5.5 0 1 1 0 1h-7z"></path></svg>
                      </label>
                    </div>
                    <div class="col-md-9 hover-elem lh-40 dark fontmedium" v-if='task.task_category'>
                      {{ task.task_category.name }}
                    </div>
                  </div>

                  <div class="row row-col-space-8 m-l-0 m-r-0" v-if='task.listed_taskable'>
                    <div class="col-md-3 hover-elem p-l-0">
                      <label for="" class="d-flex align-center justify-between m-b-0-hard lh-40">
                        <span>{{ this.$i18n.t('task_modal.details.referenced_label') }}</span>
                        <svg data-v-3d990fac="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path data-v-3d990fac="" fill="#a1a8c3" fill-rule="evenodd" d="M6 19h12v-8.281L12 5.18 6 10.72V19zm-1 .5v-9a.5.5 0 0 1 .16-.367l6.5-6a.5.5 0 0 1 .68 0l6.5 6a.5.5 0 0 1 .16.367v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5zm7-6.5a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-3.5 4a.5.5 0 1 1 0-1h7a.5.5 0 1 1 0 1h-7z"></path></svg>
                      </label>
                    </div>

                    <div class="col-md-9 hover-elem lh-40 dark fontmedium text-truncate" v-if='task.listed_taskable'>
                      <a :href="entity_link" :data-modal-click='modalDataAttribute' :data-url='task.listed_taskable.entity_url'>{{ task.listed_taskable.taskable_name }}</a>
                    </div>
                  </div>

                  <!-- Attachments -->

                  <div data-attachment-parent="true" v-if='task.attachment_ids'>
                    <div data-nested-attachment='container' :data-association='association_name' data-nested-association='container' class="task-attachment-container">
                      <div class="task-modal-attachment-group relative">
                        <label for='TaskAttachment' class="fs-13 attachment-label d-inline-block lh-40 pointer">{{ this.$i18n.tc('quick_add_task.attachments') }}</label>
                        <multiple-file-upload @attachment_update='updateValue(task.attachables_attachments_attributes, $event)' :value='task.attachment_ids' :association_value_ids='task.attachment_ids' required_class='' attachment_label_name="TaskAttachment" t_add_file='Add File' :single_attachment='false' t_attachments='Attachments' file_field_selector='[data-attachment-file-field=true]' :attachment_type='attachment_type' parent_selector='[data-attachment-parent=true]' t_file_upload_hint='(max size 500 MB)' :attachments='task.attachables_attachments_attributes' v-model="task.attachment_ids" :class="{ 'attachment-unavailable': !task.attachment_ids.length, 'attachment-available' : task.attachment_ids.length }"></multiple-file-upload>
                      </div>
                    </div>
                  </div>

                  <div class="row row-col-space-15">
                    <div class="col-md-12">
                      <label for="task_description" class="description-label-icon m-b-0-hard lh-40"><svg  xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#A1A8C3" fill-rule="evenodd" d="M.5 3a.5.5 0 1 1 0-1h15a.5.5 0 1 1 0 1H.5zm0 4a.5.5 0 0 1 0-1h9a.5.5 0 0 1 0 1h-9zm0 4a.5.5 0 0 1 0-1h15a.5.5 0 1 1 0 1H.5zm0 4a.5.5 0 1 1 0-1h9a.5.5 0 1 1 0 1h-9z"></path></svg></label>
                      <in-place-editor
                        @editable='escapePressed'
                        :value='task.description'
                        @changed='submitForm'
                        v-model='task.description'
                        :can_be_edited='!task.is_archived || currentUser.owner_or_super_admin'
                        :textarea='true'
                        container_classes='overflow-y'
                        display_text_class='d-height-min form-control place-holder description-textarea p-t-0-hard m-l-r-10'
                        input_value_class='mh-66 form-control place-holder description-textarea p-t-0-hard m-l-r-10'
                        :placeholder='this.$tc("task_modal.description_placeholder")'
                        ></in-place-editor>
                    </div>
                  </div>

                  <hr />

                  <div class="row row-col-space-10 p-t-b-5" v-if='task.creator'>
                    <div class="col-md-12 fs-11 text-center tooltip-inline">
                      <span>
                        {{ this.$i18n.t('task_modal.details.created_by')}}
                        <div class="pointer d-inline-block">
                          <user-popover-tooltip :user_id="task.creator.id"></user-popover-tooltip>
                        </div>. {{ task.formatted_created_at }}
                        <span v-if='!task.is_newly_created'>- {{ this.$i18n.t('task_modal.details.last_edited', { edited: task.formatted_updated_at }) }}</span>
                        <span v-if='task.last_netsuite_sync_at'>. Last Netsuite synced at - {{ task.last_netsuite_sync_at | formatSIDateWithTime }}</span>
                      </span>
                    </div>
                  </div>

                </div>

                <div role="tabpanel" class="tab-pane overflow-t-unset" id="comments">
                  <div v-if='this.loading_comments'>Loading Comments...</div>
                  <div v-else>
                    <comment-list
                      ref='commentsList'
                      @commentDelete='commentsChanged'
                      @commentUpdated='commentsChanged'
                      :user_options='follower_options'
                      :comment_thread='task.comment_thread'
                      :commentable_uuid='task.uuid'
                      :comments='comments_list'
                    >
                    </comment-list>
                    <new-comment
                      ref='newCommentComponent'
                      @newCommentAdded='commentsChanged($event)'
                      :user_options='follower_options'
                      :commentable='task'
                      :current_user='currentUser'
                      :allowed_char_count='500'
                      :comment_thread='task.comment_thread'
                    >
                    </new-comment>
                  </div>
                </div>

                <div role="tabpanel" class="tab-pane" id="timelogs">
                  <task-time-log-list
                    v-if='this.load_timelog'
                    @changed='loadTimeLogs'
                    @edit-time-log='editTimeLog($event)'
                     />
                </div>

                <div role="tabpanel" class="tab-pane" id="history">
                  <p v-if='this.loading_history'>Loading...</p>
                  <task-history
                    v-if='!this.loading_history'
                    :versions='task.task_versions'
                    :task='task'
                  >
                  </task-history>
                </div>
              </div>
            </article>
          </form>
        </div>
      </template>
    </si-modal>
  </div>
</template>

<script>

  import inPlaceEditor from 'components/shared/fields_editor/in_place_editor';
  import UserProfilePicWithTooltip from 'components/shared/users/user_profile_pic_with_tooltip.vue';
  import UserPopoverTooltip from 'components/shared/users/user_popover_tooltip.vue';
  import error from 'components/shared/error.vue';
  import { isAnArray, isArrayEqual } from 'concerns/dataUtilities';
  import siModal from 'components/shared/si_modal';
  import dateField from 'components/shared/date_field.vue';
  import multipleFileUpload from 'components/shared/multiple_file_upload';
  import userSelector from 'components/shared/users/user_selector';
  import AutoCompleteFollowers from 'components/shared/users/auto_complete_followers';
  import timeLogModal from 'components/tasks/time_log_modal';
  import taskMarkCompleteModal from 'components/tasks/task_mark_complete_modal';
  import markCompleteButton from 'components/tasks/mark_complete_button';
  import logTimeButton from 'components/tasks/log_time_button';
  import timePicker from 'components/global/time_picker.vue';
  import taskHistory from 'components/tasks/task_history';
  import newComment from 'components/shared/new_comment';
  import sweetAlert from 'components/shared/sweet_alert';
  import commentList from 'components/shared/comment_list';
  import taskTimeLogList from 'components/tasks/task_timelog_list.vue';
  import NetsuiteGridContent from 'components/shared/netsuite/netsuite_grid_content.vue';
  import { hoursAndMinutes } from 'concerns/time_duration_methods.js';
  import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';

  // mixins
  import { taskActionMixin } from 'mixin/tasks/task_action_mixin.js';

  import { createHelpers } from 'vuex-map-fields';

  const { mapFields: mapTaskDetailFields } = createHelpers({
    getterType: 'getTask',
    mutationType: 'updateTask'
  });

  const { mapFields: mapCommentsField } = createHelpers({
    getterType: 'getComment',
    mutationType: 'createComment'
  });

  const { mapFields: mapTimeLogFields } = createHelpers({
    getterType: 'getTimeLog',
    mutationType: 'createTimeLog'
  });

  export default {
    name: 'taskDetailModal',
    props: [
      'task_id',
      'can_manage',
      'follower_options',
      'assignee_options'
    ],
    mixins: [
      taskActionMixin
    ],
    data: function() {
      return {
        edit_followers: false,
        edit_assignee: false,
        assignee_id: this.assigneeId,
        comments_list: []
      }
    },
    methods: {
      focusModal: function() {
        this.$refs.taskDetailModal.focusModal();
      },
      editAssignee: function() {
        if(this.task.is_archived && !this.currentUser.owner_or_super_admin) {
          return;
        }
        this.edit_assignee = true;
        this.edit_followers = false;
      },
      hideTaskTimeLogModal: function() {
        this.$refs.taskDetailModal.removeModalClass('last-modal');
        this.focusModal();
      },

      showTimeLogModalForm: function() {
        this.displayTimeLogModal = true;
        this.currentLoggable = this.task;
        this.$nextTick(() => {
          this.$refs.taskDetailModal.addModalClass('last-modal');
          this.escapePressed();
        });
      },

      showMarkCompleteModal: function() {
        this.displayMarkCompleteModal = true;
        this.currentLoggable = this.task;
        this.$nextTick(() => {
          this.$refs.taskDetailModal.addModalClass('last-modal');
          this.escapePressed();
        });
      },

      openModal: function() {
        if(!this.ajaxRequestSent){
          this.loadTaskData();
        }
      },

      loadTaskData: function() {
        var _this = this;
        this.ajaxRequestSent = true

        this.loadTaskDetail({
          apollo: this.$apollo,
          task_id: this.entity_id.toString(),
          translation: this.$i18n,
          componentObject: this,
          showAlert: this.$root.showAlert
        }).then(function(){
          _this.openModalAndReset();
          _this.reset_comment = false;
          _this.setTaskUpdateBit(true);
        })
      },

      switchTabTo: function(tabReference) {
        $(this.$refs[tabReference]).find('a').click();
      },

      openModalAndReset: function() {
        if(this.modalOpened){
          this.switchTabTo('detailsTab');
        } else {
          this.modalOpened = true;
          this.$refs.taskDetailModal.displayModal();
        }
      },

      loadHistory: function() {
        if(!this.historyTabActive()){
          this.loading_history = true;
          this.loadTaskHistory({
            apollo: this.$apollo,
            task_id: this.entity_id.toString(),
            translation: this.$i18n,
            componentObject: this,
          });
        }
        this.resetComment();
      },

      loadComments: function() {
        if(!this.commentsTabActive()){
          this.loading_comments = true;
          this.fetchComments();
          this.resetComment();
        }
      },

      fetchComments: function(argument) {
        var _this = this;

        this.loadTaskComments({
          apollo: this.$apollo,
          task_id: this.entity_id.toString(),
          translation: this.$i18n,
          showAlert: this.$root.showAlert,
          componentObject: this,
        }).then(function(comments){
          _this.$nextTick(() => _this.comments_list = _this.task.comments);
        });
      },

      commentsChanged: function(comment) {
        this.fetchComments();
      },

      editTimeLog: function(data) {
        this.time_log_object.duration = hoursAndMinutes(data.duration)
        this.time_log_object.id = data.id
        this.time_log_object.description = data.description
        this.time_log_object.loggable_date = data.loggable_date
        this.showTimeLogModalForm()
      },

      loadTimeLogs: function() {
        var _this = this;
        this.loadTaskTimeLog({
          apollo: this.$apollo,
          task_id: this.entity_id.toString(),
          translation: this.$i18n,
          componentObject: this,
        }).then(() => {
          this.load_timelog = true;
          _this.setTaskUpdateBit(true);
        })
      },

      timelogTabActive: function() {
        return $(this.$refs.timeLogTab).hasClass('active');
      },

      historyTabActive: function() {
        return $(this.$refs.historyTab).hasClass('active');
      },

      commentsTabActive: function() {
        return $(this.$refs.commentsTab).hasClass('active');
      },

      editFollowers: function(openList) {
        if(this.can_manage && (!this.task.is_archived || this.currentUser.owner_or_super_admin) && !this.edit_followers) {
          this.edit_followers = true;
        }
        if(openList) {
          this.$nextTick(function(){
            this.$refs.autoComplete.focusField();
          })
        }
      },

      updateFollower: function() {
        if(!this.ajaxRequestSent) {
          var _this = this;

          this.ajaxRequestSent = true;
          this.updateTaskFollowers({
            apollo: this.$apollo,
            loggable_id: this.task.id,
            showAlert: this.$root.showAlert,
            task_follower_ids: this.task.task_follower_ids,
            componentObject: this
          }).then(function(){
            _this.setTaskUpdateBit(true);
          });
          this.focusModal();
        }
      },

      submitForm: function() {
        if(!this.ajaxRequestSent && this.modalOpened) {
          var _this = this;

          this.ajaxRequestSent = true;
          this.taskUpdate({
            apollo: this.$apollo,
            assignee_id: this.assigneeId,
            showAlert: this.$root.showAlert,
            componentObject: this
          }).then(function(){
            _this.setTaskUpdateBit(true);
          });
          this.focusModal();
        }
      },

      handleModalClose: function() {
        this.escapePressed();
        this.reset_comment = true;
        this.entity_id = '';
        this.task = {};
        this.modalOpened = false;
      },

      userChanged: function(selected) {
        this.task.assignee = selected;
        this.edit_assignee = false;
        if(!this.edit_assignee) {
          this.submitForm();
        }
      },
      dueDateChanged: function() {
        if(this.modalOpened && !this.ajaxRequestSent){
          this.submitForm();
        }
      },
      escapePressed: function(){
        this.edit_followers = false;
        this.edit_assignee = false;
      },

      hideModal: function() {

        var notEditingFields = [this.edit_assignee, this.edit_followers].every(function(element, _index){
          return element == false;
        });

        if(notEditingFields) {
          this.$refs.taskDetailModal.hideModal();
        } else {
          this.edit_assignee = false;
          this.edit_followers = false;
        }
        this.$refs.newCommentComponent.resetComment();
      },

      updateValue: function(oldObject, newValue) {
        let storePosition = oldObject
        let filePresent = false;
        for (const [index, file] of storePosition.entries()) {
          if (file.attachment_id == newValue['attachment_id']) {
            storePosition[index] = newValue
            filePresent = true
          }
        }
        if (!filePresent) {
          storePosition.push(newValue)
        }
      },

      timeLogCreateSuccess: function(task) {
        var highlightElement = $(this.$refs.timeLoggedValue);
        this.updateTaskObject(task);
        if(highlightElement.length){
          highlightElement.effect('highlight');
        }
        this.setTaskUpdateBit(true);
        this.loadTimeLogIfActive();
      },

      loadTimeLogIfActive: function() {
        if(this.timelogTabActive()) {
          this.loadTimeLogs();
        }
      },

      resetComment: function() {
        this.$refs.newCommentComponent.resetComment();
      },

      updateAttachments: function(attachment_ids) {
        if(!this.ajaxRequestSent) {
          var _this = this;

          this.ajaxRequestSent = true;
          this.updateTaskAttachments({
            apollo: this.$apollo,
            loggable_id: this.task.id,
            attachment_ids: attachment_ids,
            showAlert: this.$root.showAlert,
            componentObject: this
          }).then(function(){
            _this.setTaskUpdateBit(true);
          });
        }
      },

      afterMarkIncompleteTask: function(task) {
        this.setTaskUpdateBit(true);
      },
      afterDeleteTask: function(task) {
        this.deletedTaskId = task.id;
        this.hideModal();
      },
      afterCancelTask: function(task) {
        this.updateTaskObject(task);
        this.setTaskUpdateBit(true);
      },
      afterArchiveTask: function(task) {
        this.updateTaskObject(task);
        this.setTaskUpdateBit(true);
        // Commented as we are showing archived in lists now so not to be removed.
        // this.archivedTaskId = task.id;
        this.$nextTick(() => { this.hideModal() });
      },
      ...mapMutations('TimeLogModalWidget', ['markCompleteCheckbox']),
      ...mapActions('TaskDetailModalWidget', [
          'loadTaskDetail',
          'taskUpdate',
          'loadTaskHistory',
          'loadTaskComments',
          'loadTaskTimeLog',
          'updateTaskFollowers',
          'updateTaskAttachments',
        ]),
      ...mapMutations('TaskDetailModalWidget', ['setTaskUpdateBit', 'updateTaskObject']),
    },

    computed: {
      entity_link: function(){
        if(this.task.listed_taskable) {
          if(this.task.listed_taskable.is_modal_previewable) {
            return 'javascript:void(0)';
          } else {
            return this.task.listed_taskable.entity_url;
          }
        }
      },

      modalDataAttribute: function() {
        if(this.task.listed_taskable) {
          if(this.task.listed_taskable.is_modal_previewable) {
            return 'card-click-overlay';
          } else {
            return '';
          }
        }
      },

      followersEmpty: function() {
        return (this.task.followers && this.task.followers.length == 0);
      },

      modalTitle: function() {
        if(this.task.task_category){
          return this.$i18n.t('task_modal.title', { id: this.task.id, task_category: this.task.task_category.name })
        }
      },

      modalDataPresent: function() {
        return !$.isEmptyObject(this.task);
      },

      assigneeId: function() {
        if(this.task.assignee) {
          return this.task.assignee.id || this.task.assignee.value;
        } else {
          return '';
        }
      },

      selectedAssignee: function() {
        var _this = this;
        return this.assignee_options.find(function(element){
          return element.value == _this.task.assignee.id;
        })
      },

      ...mapTaskDetailFields('TaskDetailModalWidget', [
          'task',
          'ajaxRequestSent',
          'updatedSuccessful',
          'attachment_type',
          'association_name',
          'modalOptions',
          'entity_id',
          'loading_history',
          'load_timelog',
          'loading_comments',
          'task_updated',
          'modalOpened'
        ]),
      ...mapCommentsField('AddCommentWidget', ['reset_comment', 'comment_object']),
      ...mapState('GeneralConfiguration', ['currentUser'])
    },

    watch: {
      entity_id: function(value) {
        if(!$.isEmptyObject(value.toString())) {
          this.openModal();
        }
      },
      displayTimeLogModal: function(value) {
        if ((!value) && (this.modalOpened)) {
          this.$refs.taskDetailModal.removeModalClass('last-modal');
          this.focusModal();
        }
      },
      displayMarkCompleteModal: function(value) {
        if ((!value) && (this.modalOpened)) {
          this.$refs.taskDetailModal.removeModalClass('last-modal');
          this.focusModal();
        }
      },
      timeLogCreateSuccessful: function(value) {
        if ((value) && (this.task.id == this.currentLoggable.id) && this.modalOpened) {
          this.timeLogCreateSuccess(this.currentLoggable);
          this.timeLogCreateSuccessful = false;
        }
      },
      timeLogUpdateSuccessful: function(value) {
        if ((value) && (this.task.id == this.currentLoggable.id) && this.modalOpened) {
          this.loadTimeLogs();
          this.timeLogUpdateSuccessful = false;
        }
      },
      edit_assignee: function(value) {
        if(value && this.modalOpened) {
          var _this = this;

          this.$nextTick(function(){
            _this.$refs.assigneeSelector.openSelector();
          });
        }
      },

      'task.attachment_ids': function(new_value, previous_value) {
        if(this.modalOpened){
          var newValues = Array.from(new_value).map((element) => element.toString()).unique();
          var previousValues = Array.from(previous_value).map((element) => element.toString()).unique();
          var isequal = isArrayEqual(newValues, previousValues);

          if(!isequal) {
            this.updateAttachments(new_value);
          }
        }
      }
    },

    components: {
      siModal,
      UserProfilePicWithTooltip,
      UserPopoverTooltip,
      error,
      inPlaceEditor,
      AutoCompleteFollowers,
      userSelector,
      dateField,
      multipleFileUpload,
      markCompleteButton,
      logTimeButton,
      timeLogModal,
      taskHistory,
      sweetAlert,
      taskMarkCompleteModal,
      NetsuiteGridContent,
      timePicker,
      newComment,
      commentList,
      taskTimeLogList
    }
  };
</script>

<style lang="css" scoped>
</style>
