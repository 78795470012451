import Vue from 'vue/dist/vue.esm';
import Moment from 'vue-moment';
import VueStringFilter from 'vue-string-filter'
import VueLodash from 'vue-lodash'
import RouteBuilder from 'route-builder';
import {} from 'concerns/snotify';
import * as deepmerge from 'deepmerge';
import { } from 'directives/shared/detect_click_outside';
import { VPopover, VTooltip } from 'v-tooltip';
import { } from 'directives/shared/permission';
import { } from 'directives/shared/uppercase';

// Mixins
import { SnotifyAlert } from 'mixin/shared/snotify_alert.js'
import { NotificationWidgetMixin } from 'mixin/shared/notification_widget.js'
import { TimePickerMixin } from 'mixin/shared/time_picker_mixin.js'
import { EventModalMixin } from 'mixin/shared/event_modal.js';
import { ScheduleModalMixin } from 'mixin/shared/schedule_modal.js';
import { destroyButtonMixin } from  'mixin/shared/destroy_button_widget.js';
import { FollowerMixin } from 'mixin/shared/follower.js';
import { QuickAddContactMixin } from 'mixin/shared/contacts/quick_add_modal_mixin.js';
import { QuickAddTaskModalMixin } from 'mixin/shared/quick_add_task_mixin.js';
import { AutoCompleteFollowersMixin } from 'mixin/shared/auto_complete_followers_mixin.js';
import { AccountQuickAddMixin } from 'mixin/shared/account_quick_add_mixin.js';
import { WorkOrderQuickShowMixin } from 'mixin/shared/work_order_quick_show_mixin.js';
import { TaskModalOpenerMixin } from 'mixin/shared/task_modal_opener_mixin.js';
import { TaskDetailModalMixin } from 'mixin/shared/task_detail_modal_mixin.js';
import { GeneralConfigurationMixin } from 'mixin/shared/general_configuration_mixin.js';
import { CreateEstimateMixin } from 'mixin/shared/create_estimate_mixin.js';
import { OpportunityQuickAddWidgetMixin } from 'mixin/shared/quick_add_opportunity_mixin.js';
import { QuickAddWorkOrderMixin } from 'mixin/shared/quick_add_work_order_mixin';
import { QuickAddWorkOrderButtonMixin } from 'mixin/shared/quick_add_work_order_button_mixin';
import { Permission } from 'mixin/shared/permission'
import { SideBarMixin } from 'mixin/shared/sidebar_mixin'
import { TopBarMixin } from 'mixin/shared/topbar_mixin'
import { PlaceholderLoadingMixin } from 'mixin/shared/placeholder_loading.js';

// Filters
import Filters from 'filters/index';

import GlobalComponents from 'components/global/index';

window.router = new RouteBuilder([
  ['work_order_schedules', '/admin/work_orders/:id/work_order_schedules'],
  ['work_order_schedules_tab', '/admin/work_orders/:id#schedules'],
  ['work_order_details', '/admin/work_orders/:id/details'],
  ['supplier_index', '/admin/suppliers'],
  ['admin_authorize', '/admin/authorize'],
  ['admin_edit', '/admin/edit'],
  ['admin_notification_preferences', '/admin/user_notification_preferences'],
  ['admin_sign_out', '/admin/sign_out'],
  ['event_show', '/admin/events/:id'],
  ['sub_work_order_schedules', '/admin/work_orders/:work_order_id/sub_work_orders/:id/schedules'],
  ['sub_work_order_schedules_tab', '/admin/work_orders/:work_order_id/sub_work_orders/:id#schedules'],
  ['sub_work_order_details', '/admin/work_orders/:work_order_id/sub_work_orders/:id/details'],
  ['all_notifications', '/admin/web_notifications'],
  ['filter_show_page', '/admin/filter_types/:id'],
  ['filter_create', '/admin/filters'],
  ['filter_update', '/admin/filters/:id'],
  ['filter_value_show', '/admin/filter_types/:filter_type_id/filter_values/:filter_value_id'],
  ['product_edit', '/admin/products/:id/edit?load_product_from_external_id=:load_product_from_external_id'],
  ['products_index', '/admin/products?open_add_item_modal=:open_add_item_modal'],
  ['designs_index', '/admin/designs'],
  ['parents_index', '/admin/parents'],
  ['product_show', '/admin/products/:id'],
  ['product_sources', '/admin/products/json_source_ids'],
  ['product_colors', '/admin/products/colors'],
  ['product_categories', '/admin/products/categories'],
  ['root_category_index', '/admin/root_categories'],
  ['root_category_show', '/admin/root_categories/:id'],
  ['work_order', '/admin/work_orders/:id'],
  ['sub_work_order', '/admin/work_orders/:work_order_id/sub_work_orders/:id'],
  ['new_sub_work_order', '/admin/work_orders/:id/sub_work_orders/new'],
  ['work_order_tasks_count', '/admin/work_orders/:id/tasks_count'],
  ['work_order_edit', '/admin/work_orders/:id/edit'],
  ['work_order_edit_with_focus', '/admin/work_orders/:id/edit?focus=true'],
  ['sub_work_order_edit_with_focus', '/admin/work_orders/:work_order_id/sub_work_orders/:id/edit?focus=true'],
  ['order_index', '/admin/orders'],
  ['vendor_options', '/admin/orders/:code/products/:id/vendor_options?li_id=:li_id&li_type=:li_type'],
  ['all_vendor_options', '/admin/orders/:code/products/:id/all_vendor_options'],
  ['order_show', '/admin/orders/:code'],
  ['order_show_with_tab', '/admin/orders/:code#:tab_name'],
  ['opportunity_index', '/admin/opportunities'],
  ['estimate_index', '/admin/estimates'],
  ['message_chain_index', '/admin/message_chains'],
  // TODO_ORDER
  ['order_edit', '/admin/orders/:id/edit'],
  ['order_edit_with_redirect', '/admin/orders/:id/edit?redirect_uri=:redirect_uri'],
  ['orders_index', '/admin/orders'],
  ['shipping_calculator', '/admin/shipment_calculator'],
  ['new_account', '/admin/accounts/new'],
  ['fetch_addresses', '/api/v1/admin/contacts/:id/fetch_addresses'],
  ['fetch_addresses_by_type', '/api/v1/admin/contacts/:id/fetch_addresses_by_type?type=:type'],
  ['fetch_po_receivable_options', '/admin/orders/:order_id/purchase_orders/:id/receivable_options'],
  ['fetch_order_receivable_options', '/api/v1/admin/orders/:id/receivable_options'],
  ['fetch_accounts', '/admin/accounts/accounts_json'],
  ['contacts_path', '/admin/accounts/:id/ajax_contacts'],
  ['shop_product', '/shop#/product?id=:id'],
  ['account_show', '/admin/accounts/:id'],
  ['account_edit', '/admin/accounts/:id/edit'],
  ['user_show', '/admin/users/:id'],
  ['account_edit_with_redirect', '/admin/accounts/:id/edit?redirect_uri=:redirect_uri'],
  ['contact_edit', '/admin/contacts/:id/edit'],
  ['contact_show', '/admin/contacts/:id'],
  ['contact_edit_with_redirect', '/admin/contacts/:id/edit?redirect_uri=:redirect_uri'],
  ['archive_order', '/admin/orders/:code/archive'],
  ['delete_order', '/admin/orders/:code'],
  ['edit_order', '/admin/orders/:code/edit'],
  ['edit_order_with_tab', '/admin/orders/:code/edit#:tab_name'],
  ['cancel_order', '/admin/orders/:code/cancel'],
  ['shipment_file_download_path', '/admin/orders/shipment_files/:id/download'],
  ['request_change_owner', '/admin/orders/:code/request_change_owner'],
  ['clone_order', '/admin/orders/:code/clone'],
  ['current_estimate_review', '/estimate_revisions/:uuid/review'],
  ['order_work_orders', '/admin/orders/work_orders'],
  ['edit_purchase_order', '/admin/orders/:order_code/purchase_orders/:code/edit'],
  ['edit_purchase_order_with_tab', '/admin/orders/:order_code/purchase_orders/:code/edit#:tab_name'],
  ['show_purchase_order', '/admin/orders/:order_code/purchase_orders/:code'],
  ['show_purchase_order_with_tab', '/admin/orders/:order_code/purchase_orders/:code#:tab_name'],
  ['purchase_order_show', '/admin/orders/:order_code/purchase_orders/:code'],
  ['related_multiple_contacts', '/api/v1/admin/accounts/related_multiple_contacts.json'],
  ['cancel_purchase_order', '/admin/orders/:order_code/purchase_orders/:code/cancel'],
  ['detach_purchase_order', '/admin/orders/:order_code/purchase_orders/:code/detach'],
  ['current_po_issuance_review', '/purchase_order_issuances/:uuid/review'],
  ['current_po_issuance_review_pdf', '/purchase_order_issuances/:uuid/review.pdf'],
  ['review_invoice_pdf', '/invoices/:code/review.pdf?type=:type'],
  ['download_invoice_pdf', '/invoices/:code/download.pdf?type=:type'],
  ['download_invoice_attachment_pdf', '/documents/:code/download'],
  ['purchase_order_dashboard', '/admin/purchase_orders'],
  ['task_listing', '/admin/task_board/smart_list'],
  ['work_order_listing', '/admin/work_orders'],
  ['archived_work_orders', '/admin/work_orders/archived_list'],
  ['preview_work_order', '/admin/work_orders/:id/preview'],
  ['preview_sub_work_order', '/admin/work_orders/:work_order_id/sub_work_orders/:id/preview'],

  ['contact_listing', '/admin/contacts'],
  ['account_listing', '/admin/accounts'],
  ['accounting_accounts_listing', '/admin/accountings/accounts'],
  ['accounting_opportunities_listing', '/admin/accountings/opportunities'],
  ['accounting_orders_listing', '/admin/accountings/orders'],
  ['accounting_estimates_listing', '/admin/accountings/estimates'],
  ['accounting_purchase_orders_listing', '/admin/accountings/purchase_orders'],
  ['resourcing_listing', '/admin/resourcing?resourcing_type=all'],
  ['resourcing_report_listing', '/admin/resourcing/report'],
  ['work_order_report_listing', '/admin/work_orders/report'],
  ['gross_profit_report_listing', '/admin/reports/financial_reports/gross_profit_report'],
  ['work_in_process_report', '/admin/reports/financial_reports/work_in_process_report'],
  ['account_receivable_report_listing', '/admin/reports/financial_reports/account_receivable_report'],
  ['unconfirmed_po_report_listing', '/admin/reports/financial_reports/unconfirmed_pos_report'],
  ['po_report_listing', '/admin/reports/financial_reports/po_report'],
  ['department_wise_revenue_report_listing', '/admin/reports/financial_reports/department_wise_revenue_report'],
  ['new_account_revenue_report_listing', '/admin/reports/financial_reports/new_account_revenue_report'],
  ['account_spend_report_listing', '/admin/reports/financial_reports/account_spend_report'],
  ['overdue_order_report_listing', '/admin/reports/financial_reports/overdue_order_report'],
  ['new_contact_revenue_report_listing', '/admin/reports/financial_reports/new_contact_revenue_report'],
  ['late_po_report_listing', '/admin/reports/financial_reports/late_po_report'],
  ['user_listing', '/admin/users'],
  ['department_listing', '/admin/departments'],
  ['size_listing', '/admin/sizes'],
  ['rules_listing', '/admin/rules'],
  ['email_templates_listing', '/admin/email_templates'],
  ['screen_printing_upcharges_listing', '/admin/screen_printing_upcharges'],
  ['screen_printing_location_listing', '/admin/screen_printing_locations'],
  ['legacy_customizer_product_listing', '/admin/legacy_customizer_products'],
  ['account_team_index', '/admin/account_teams'],
  ['shipment_files_listing', '/admin/orders/shipment_files'],
  ['current_estimate_pdf', '/estimate_revisions/:uuid/review.pdf'],
  ['product_configuration_listing', '/admin/product_configurations'],
  ['work_order_time_log', '/admin/work_orders/:work_order_id/time_logs/:id'],
  ['work_order_sub_work_order_time_log', '/admin/work_orders/:work_order_id/sub_work_orders/:sub_work_order_id/time_logs/:id'],
  ['check_list_item_show', '/admin/check_list_items/:id.js?listable_type=:listable_type&listable_id=:listable_id'],
  ['work_order_check_list_item_path', '/admin/work_orders/:work_order_id/check_list_items?check_list_item_id=:id'],
  ['accounts_search_path', '/api/v1/admin/accounts/search'],
  ['ajax_contacts', '/api/v1/admin/accounts/:id/ajax_contacts'],
  ['ajax_contacts_with_selected_contacts', '/api/v1/admin/accounts/:id/ajax_contacts?selected_contacts=:selected_contacts'],
  ['file_download_template_path', '/admin/files/download_template?product_ids_json=:product_ids_json&template_type=:template_type'],
  ['hold_instructions_listing', '/admin/hold_instructions'],
  ['netsuite_logs_listing', '/admin/netsuite_logs'],

  //Dashboards
  ['am_todos_dashboard', '/admin/dashboards/todo_dashboards/am_dashboard'],
  ['am_todos_workorders', '/admin/work_orders/smart_list/?q[filter_all_user_by]=:param'],
  ['am_todos_tasks', '/admin/task_board/smart_list/?q[filter_all_user_by]=:param'],
  ['am_todos_opportunities', '/admin/opportunities/?q[filter_all_user_by]=:param'],
  ['am_todos_activities', '/admin/calendar/?q[filter_user_by]=:param'],
  ['am_todos_estimates', '/admin/estimates/?q[filter_all_user_by]=:param'],
  ['am_todos_orders', '/admin/orders/?q[filter_all_user_by]=:param'],
  ['am_todos_events', '/admin/calendar/?q[filter_user_by]=:param'],
  ['homeviews_biz_dev_listing', '/admin/home_views/bizdev']
]);

Vue.config.devtools = true;

Vue.use(Moment);
Vue.use(VueStringFilter);
Vue.use(VueLodash);
Vue.directive('tooltip', VTooltip);
Vue.component('v-popover', VPopover);

export const DefaultMixins = [
  SnotifyAlert,
  NotificationWidgetMixin,
  AccountQuickAddMixin,
  TimePickerMixin,
  EventModalMixin,
  ScheduleModalMixin,
  WorkOrderQuickShowMixin,
  FollowerMixin,
  AutoCompleteFollowersMixin,
  QuickAddContactMixin,
  destroyButtonMixin,
  QuickAddTaskModalMixin,
  TaskModalOpenerMixin,
  GeneralConfigurationMixin,
  TaskDetailModalMixin,
  CreateEstimateMixin,
  OpportunityQuickAddWidgetMixin,
  QuickAddWorkOrderMixin,
  QuickAddWorkOrderButtonMixin,
  Permission,
  SideBarMixin,
  TopBarMixin,
  PlaceholderLoadingMixin
];
